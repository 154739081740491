import { usePetConfig } from "@hooks/usePetConfig";
import { format } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { PetGender, PetSizeType } from "../../../../../types/pets";
import { BreedType } from "@types";
import React from "react";
import { FeedingGuideAccordion } from "@components/DietInfoCard/FeedingGuide/FeedingGuideAccordion";
import { MY_PETS } from "@constants/myPets";

export interface ShareProfilePrintProps {
  petImage?: string;
  nickname: string;
  breedId: number;
  genderId: number;
  birthdate: Date;
  petTypeId: number;
  sizeId: number;
  productId: string;
  productName: string;
  feedingGuide: string;
}

export const ShareProfilePrint: React.FC<ShareProfilePrintProps> = ({
  petImage,
  nickname,
  breedId,
  genderId,
  birthdate,
  petTypeId,
  sizeId,
  productId,
  productName,
  feedingGuide,
}) => {
  const { isLoaded, getBreed, getGender, getSize } = usePetConfig();
  const [breed, setBreed] = useState<BreedType | null>(null);
  const [gender, setGender] = useState<PetGender | null>(null);
  const [size, setSize] = useState<PetSizeType | null>(null);

  useEffect(() => {
    if (getBreed && getGender && getSize && isLoaded?.()) {
      const breed = getBreed(breedId);
      const gender = getGender(genderId);
      const size = getSize(petTypeId, sizeId);
      if (breed && gender && size) {
        setBreed(breed);
        setGender(gender);
        setSize(size);
      }
    }
  }, [breedId, genderId, petTypeId, sizeId]);

  return (
    <div className="w-[834px] bg-white font-montserrat">
      <div className="flex flex-col gap-8 bg-gray-100">
        <div className="m-5 flex flex-col">
          <img
            src="/images/hills-pet-logo.png"
            alt="Hills Pet Nutrition"
            className="absolute h-12 w-12"
          />
          <div className="w-full px-[92px] pt-6 pb-8 text-center text-3xl font-bold">
            {t(MY_PETS.SHARE_PET_PROFILE.PET_TITLE, { petName: nickname })}
          </div>
          <div className="flex flex-row gap-6 align-middle">
            <div
              className="h-16 w-16 rounded-full bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${petImage})` }}
              role="img"
              aria-label={nickname}
            />
            <div className="grid grid-cols-2 gap-x-6">
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.NICKNAME)}
                value={nickname}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.BIRTHDATE)}
                value={format(birthdate, "MMMM d, yyyy")}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.BREED)}
                value={breed?.petBreedName ?? ""}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.SIZE)}
                value={size?.petSizeName ?? ""}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.GENDER)}
                value={gender?.petGenderName ?? ""}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="m-10 flex flex-col gap-4">
        <div className="text-2xl font-bold text-gray-800">
          {t(MY_PETS.SHARE_PET_PROFILE.DIET)}
        </div>
        <div className="flex flex-row items-start gap-6">
          <div className="flex w-[514px] flex-col gap-3">
            <div className="flex flex-col">
              <div className="text-base font-normal text-gray-900">
                {productName}
              </div>
              <div className="text-base font-normal text-gray-900">
                SKU: {productId}
              </div>
            </div>
            <div className="text-base font-semibold text-gray-600">
              {t(MY_PETS.SHARE_PET_PROFILE.FEEDING_GUIDE)}
            </div>
            <hr className="border-brand-color-library-gray-300" />

            <FeedingGuideAccordion
              contentHtml={feedingGuide}
              isOpen={true}
              renderAsPdf={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface FieldProps {
  label: string;
  value: string;
}

const Field = ({ label, value }: FieldProps) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="text-lg text-gray-500">{label}</div>
      <div className="overflow-hidden truncate text-lg leading-10 text-gray-900">
        {value}
      </div>
    </div>
  );
};
