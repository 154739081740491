import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader } from "ui-2";
import { debounce, usePromise } from "utils";
import { PetType } from "../../../../../../types";
import { setPetStatus } from "../../../../../api-calls/useUpdatePetStatus";
import { useToastConfig } from "../../../../../components/toast";
import GTM_ACTIONS from "../../../../../config/gtmActions";
import { ADD_EDIT_PET } from "../../../../../constants/addEditPet";
import { MY_PETS } from "../../../../../constants/myPets";
import { ROUTES_PATH } from "../../../../../constants/routes";
import { useMyPets } from "../../../../../hooks/useMyPet";
import useSearchPersistantNavigate from "../../../../../hooks/useSearchPersistantNavigate";
import {
  DeleteIcon,
  HideIcon,
  KebabSvg,
  MobileKebabSvg,
  ShowIcon,
  ShareIcon,
} from "../../../../../icons";
import { datalayerPush } from "../../../../../lib/gtm";
import MobileKebabModal from "./MobileKebabModal";

const KebabMenu = ({
  petData,
  setLoader,
  handleShareProfileClick,
  isSharing,
  sharePetProfileEnabled,
}: {
  petData: PetType;
  setLoader: (show: boolean) => void;
  handleShareProfileClick: () => Promise<void>;
  isSharing: boolean;
  sharePetProfileEnabled: boolean;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { showToast } = useToastConfig();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isViewRememberedPets, setIsViewRememberedPets, refetchPetList } =
    useMyPets();
  const hideShowtext = isViewRememberedPets
    ? t(MY_PETS.SHOW_HIDE_POPUP.UNHIDE_PET)
    : t(MY_PETS.SHOW_HIDE_POPUP.HIDE_PET);
  const hideShowIcon = isViewRememberedPets ? ShowIcon : HideIcon;
  const [
    { isPending: petStatusUpdatePending, isError: petStatusUpdateError, data },
    { exec },
  ] = usePromise(setPetStatus);
  const searchPersistantNavigate = useSearchPersistantNavigate();

  useEffect(() => {
    if (petStatusUpdateError) {
      const responsedata = data?.data;
      refetchPetList();
      navigate(ROUTES_PATH.MY_PETS);
      showToast({
        title: `${t(ADD_EDIT_PET.ADDED)}`,
        description: `${t(
          `addEditPet.${responsedata?.serviceStatus.message}`
        )}`,
        type: "error",
      });
    }
  }, [petStatusUpdateError]);

  const handleShowHideClick = async () => {
    if (isViewRememberedPets) {
      //unhide pet
      setShowMenu(false);
      setLoader(true);
      const reponse = await exec({ petId: petData.petId, petStatusId: 1 });
      if (reponse?.status === 200) {
        setLoader(false);
        const responsedata = reponse?.data;
        showToast({
          title: `${t(ADD_EDIT_PET.ADDED)}`,
          description: `${t(
            `addEditPet.${responsedata.serviceStatus.message}`
          )}`,
          type: "success",
        });
        refetchPetList();
        setIsViewRememberedPets?.(false);
      } else {
        showToast({
          title: t(ADD_EDIT_PET.PET_ADDING_ERROR),
          status: "error",
        });
      }
    } else {
      //hide pet
      datalayerPush(GTM_ACTIONS.MY_PETS_PROFILE_KEBAB_HIDE_A_PET);
      searchPersistantNavigate(`${ROUTES_PATH.HIDE_PET}`, {
        state: petData,
      });
    }
  };

  const handleDeleteClick = async () => {
    datalayerPush(GTM_ACTIONS.MY_PETS_PROFILE_KEBAB_DELETE_A_PET);
    searchPersistantNavigate(`${ROUTES_PATH.DELETE_PET}`, {
      state: petData,
    });
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth < 1024);
    }, 200);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuToggle = () => {
    setShowMenu((prev) => !prev);
  };

  const handleModalToggle = () => {
    setShowModal((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const handleShareClick = async () => {
    if (!isSharing) {
      try {
        await handleShareProfileClick();
      } finally {
        setShowMenu(false); // Close the menu after sharing is completed (success or failure)
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            onClick={handleModalToggle}
            className="cursor-pointer"
            data-testid="kebab-menu-icon"
          >
            {MobileKebabSvg}
          </div>
          <MobileKebabModal
            show={showModal}
            onClose={() => setShowModal(false)}
            hideShowtext={hideShowtext}
            handleShareProfileClick={handleShareProfileClick}
            handleShowHideClick={handleShowHideClick}
            handleDeleteClick={handleDeleteClick}
          />
        </>
      ) : (
        <div
          className="relative flex text-left"
          ref={menuRef}
          data-testid="kebab-menu"
        >
          <div
            onClick={handleMenuToggle}
            className="cursor-pointer"
            data-testid="kebab-menu-icon"
          >
            {KebabSvg}
          </div>
          {showMenu && (
            <div className="absolute ml-10 min-w-max rounded-lg bg-white py-2 shadow-dp16">
              {sharePetProfileEnabled && (
                <div
                  className="label2 mx-6 my-4 flex cursor-pointer select-none items-center text-brand-primary"
                  onClick={handleShareClick}
                >
                  {isSharing ? (
                    <>
                      <Loader size="small" />
                      {t(MY_PETS.SHOW_HIDE_POPUP.SHARING)}
                    </>
                  ) : (
                    <>
                      <span className="mr-2">
                        {isSharing ? <Loader size="small" /> : ShareIcon}
                      </span>
                      {t(MY_PETS.SHOW_HIDE_POPUP.SHARE_PROFILE)}
                    </>
                  )}
                </div>
              )}
              <div
                data-testid="kebab-menu-hide-unhide"
                className="label2 justify-left mx-6 my-4 flex cursor-pointer select-none items-center text-brand-primary"
                onClick={handleShowHideClick}
              >
                {petStatusUpdatePending ? (
                  <Loader />
                ) : (
                  <>
                    <span className="mr-2">{hideShowIcon}</span>
                    {hideShowtext}
                  </>
                )}
              </div>
              <div
                className="label2 mx-6 my-4 flex cursor-pointer select-none items-center text-brand-error"
                onClick={handleDeleteClick}
              >
                <span className="mr-2">{DeleteIcon}</span>
                {t(MY_PETS.SHOW_HIDE_POPUP.DELETE_PET)}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default KebabMenu;
